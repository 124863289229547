import {useState, useEffect} from "react";
import {
  Grid,
  CircularProgress,
  Typography,
  Button,
  TextField,
  Card,
  CardContent
} from "@material-ui/core";
import {withRouter} from "react-router-dom";

import Header from "../../components/Header/Header"; 

//validate form
import * as Yup from 'yup';
import {Formik} from 'formik';

//notification
import Alert from "../../components/Alert/Alert";

// styles
import useStyles from "./styles";

//lottie
import Lottie from "lottie-react";
import animationData from '../../animations/christmas.json'

// context
import {sponsorKitCompany} from "../../context/SponsorContext";

function CompanyForm(props) {

    const classes = useStyles();

    // local
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [typeError, setTypeError] = useState(null);
    const [notify, setNotify] = useState({isOpen: false, msg:'', type:''});
    const [isDisabled, setIsDisabled] = useState(false);

    //validaciones error
    useEffect(() => {
        if(typeError === 0) {
            //redirect
        }
        else if (error && typeError === 1) {
            setNotify({
                isOpen: true,
                msg: 'Error al patrocinar una sonrisa, favor intentar más tarde',
                type: 'error'
            })
        }
        else if (error && typeError === 2) {
            setNotify({
                isOpen: true,
                msg: 'Debe llenar todos los campos',
                type: 'error'
            }); 
        }
        else if (error && typeError === 3) {
            setNotify({
                isOpen: true,
                msg: 'Error al digitar el teléfono celular',
                type: 'error'
            }); 
        }
        else if (error && typeError === 4) {
            setNotify({
                isOpen: true,
                msg: 'Error, cliente ya se encuentra registrado',
                type: 'error'
            }); 
        }
        else if (error && typeError === 5) {
            setNotify({
                isOpen: true,
                msg: 'Muchas gracias por colaborar llegamos a la meta!',
                type: 'info'
            }); 
        }
    }, [error, typeError]);

    return (
        <>
            <Header />
            <Grid container className={classes.container}>
                <Alert notify={notify} setNotify={setNotify} />
                <Lottie animationData={animationData} className={classes.logotypeContainer} />
                <div className={classes.formContainer}>
                    <div className={classes.form}>
                        <Formik
                            initialValues={{
                                name: '',
                                email: '',
                                phone: '',
                                company: ''
                            }}
                            validationSchema={Yup.object().shape({
                                name: Yup.string().max(255).required('Nombre es requerido'),
                                email: Yup.string().email('Se necesita un correo electrónico válido').max(255).required('Correo electrónico es requerido'),
                                phone: Yup.string().max(255).required('Teléfono es requerido'),
                                company: Yup.string().max(255).required('Empresa Participante es requerida'),
                            })}
                            onSubmit={(values) =>
                                sponsorKitCompany(
                                    values.name,
                                    values.email,
                                    values.phone,
                                    values.company,
                                    setIsLoading,
                                    setError,
                                    setTypeError,
                                    setIsDisabled,
                                    props.history
                                )}
                            >
                            {({
                                errors,
                                handleBlur,
                                handleChange,
                                handleSubmit,
                                touched,
                                values
                            }) => (
                                <form onSubmit={handleSubmit}>
                                    <Card className={classes.card}>
                                        <CardContent>
                                            <div className={classes.formDividerContainer}>
                                                <Typography variant="h2" className={classes.subGreeting}>
                                                    Formulario Sonrisas
                                                </Typography>
                                            </div>
                                            <TextField
                                                error={Boolean(touched.name && errors.name)}
                                                fullWidth
                                                helperText={touched.name && errors.name}
                                                label="Nombre Completo"
                                                name="name"
                                                type="text"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.name}
                                                variant="outlined"
                                                margin="normal"
                                                required
                                                disabled={isDisabled}
                                            />
                                            <TextField
                                                error={Boolean(touched.email && errors.email)}
                                                fullWidth
                                                helperText={touched.email && errors.email}
                                                label="Correo Electrónico"
                                                name="email"
                                                type="email"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.email}
                                                variant="outlined"
                                                margin="normal"
                                                required
                                                disabled={isDisabled}
                                            />
                                            <TextField
                                                error={Boolean(touched.phone && errors.phone)}
                                                fullWidth
                                                helperText={touched.phone && errors.phone}
                                                label="Teléfono Celular"
                                                name="phone"
                                                type="number"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.phone}
                                                variant="outlined"
                                                margin="normal"
                                                required
                                                disabled={isDisabled}
                                            />
                                            <TextField
                                                error={Boolean(touched.company && errors.company)}
                                                fullWidth
                                                helperText={touched.company && errors.company}
                                                label="Empresa Participante"
                                                name="company"
                                                type="text"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.company}
                                                variant="outlined"
                                                margin="normal"
                                                required
                                                disabled={isDisabled}
                                            />
                                            <div className={classes.FormButton}>
                                                {isLoading ? (
                                                    <CircularProgress size={26} />
                                                ) : (
                                                    <Button
                                                        size="large"
                                                        variant="contained"
                                                        fullWidth
                                                        color="primary"
                                                        className={classes.Button}
                                                        disabled={isDisabled}
                                                        type="submit"
                                                    >
                                                        PATROCINAR
                                                    </Button>
                                                )}
                                            </div>
                                        </CardContent>
                                    </Card>
                                </form>
                            )}
                        </Formik>
                    </div>
                </div>
            </Grid>
        </>
    );
}

export default withRouter(CompanyForm);