import {Grid, Button} from "@material-ui/core";
import {Link} from "react-router-dom";

import Header from "../../components/Header/Header";

// logo
import error from "../../animations/page-not-found.png";

// styles
import useStyles from "./styles";

export default function Error() {
    
    const classes = useStyles();

    return (
        <>
            <Header />
            <Grid container className={classes.container}>
                <div className={classes.logotype}>
                    <img className={classes.logoError} src={error} alt="logo-error" />
                </div>
                <Button
                    variant="contained"
                    color="primary"
                    component={Link}
                    to="/"
                    size="large"
                    className={classes.backButton}
                >
                    Inicio
                </Button>
            </Grid>
        </>
    );
};
