import {receiveKit} from "../services/index";

export const sponsorKit = async (name, email, phone, setIsLoading, setError, setTypeError, setIsDisabled, history) => {

    //estado inicial en la función
    setError(false);
    setIsLoading(true);
    setIsDisabled(true);
    setTypeError(null);

    if(!!name && !!email && !!phone)  {

        //validar telefono
        const numberPhone = phone.toString().length

        if(numberPhone !== 8) {
            setError(true);
            setTypeError(3);
            setIsLoading(false);
            setIsDisabled(false);
            setTypeError(null);
        }
        else{
            const sponsor = await receiveKit(name, email, phone)
    
            if(sponsor.status === 404 || sponsor.status === 400 || sponsor.status === 502 || sponsor.status === 500) {
                setError(true);
                setTypeError(1);
                setIsLoading(false);
                setIsDisabled(false);
                setTypeError(null);
            }
            else if(sponsor.status === 203 && sponsor.data.message.type === 1){
                setError(true);
                setTypeError(4);
                setIsLoading(false);
                setIsDisabled(false);
                setTypeError(null);
            }
            else if(sponsor.status === 203 && sponsor.data.message.type === 2){
                setError(true);
                setTypeError(5);
                setIsLoading(false);
                setIsDisabled(false);
                setTypeError(null);
            }
            else{
                setError(null);
                setTypeError(0);
                setIsLoading(false);
                setIsDisabled(false);
                setTypeError(null);

                history.push({ 
                    pathname: '/result',
                    name: sponsor.data.sponsor[0].nino,
                    age: sponsor.data.sponsor[0].edad,
                    sex: sponsor.data.sponsor[0].sexo
                });
            }
        }
    }
    else {
        setError(true);
        setTypeError(2);
        setIsLoading(false);
        setIsDisabled(false);
        setTypeError(null);
    }
};

export const sponsorKitCompany = async (name, email, phone, company, setIsLoading, setError, setTypeError, setIsDisabled, history) => {

    //estado inicial en la función
    setError(false);
    setIsLoading(true);
    setIsDisabled(true);
    setTypeError(null);

    if(!!name && !!email && !!phone && !!company)  {

        //validar telefono
        const numberPhone = phone.toString().length

        if(numberPhone !== 8) {
            setError(true);
            setTypeError(3);
            setIsLoading(false);
            setIsDisabled(false);
            setTypeError(null);
        }
        else{
            const sponsor = await receiveKit(name, email, phone, company)
    
            if(sponsor.status === 404 || sponsor.status === 400 || sponsor.status === 502 || sponsor.status === 500) {
                setError(true);
                setTypeError(1);
                setIsLoading(false);
                setIsDisabled(false);
                setTypeError(null);
            }
            else if(sponsor.status === 203 && sponsor.data.message.type === 1){
                setError(true);
                setTypeError(4);
                setIsLoading(false);
                setIsDisabled(false);
                setTypeError(null);
            }
            else if(sponsor.status === 203 && sponsor.data.message.type === 2){
                setError(true);
                setTypeError(5);
                setIsLoading(false);
                setIsDisabled(false);
                setTypeError(null);
            }
            else{
                setError(null);
                setTypeError(0);
                setIsLoading(false);
                setIsDisabled(false);
                setTypeError(null);

                history.push({ 
                    pathname: '/result',
                    name: sponsor.data.sponsor[0].nino,
                    age: sponsor.data.sponsor[0].edad,
                    sex: sponsor.data.sponsor[0].sexo
                });
            }
        }
    }
    else {
        setError(true);
        setTypeError(2);
        setIsLoading(false);
        setIsDisabled(false);
        setTypeError(null);
    }
};