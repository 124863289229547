import {
    AppBar,
    Toolbar,
} from '@material-ui/core';

import logo from "../../animations/logo-fundacion.png";

import useStyles from './styles';

export default function MenuBar() {

    const classes = useStyles();

    return (
        <div className={classes.root}>
            <AppBar position="static" className={classes.bar}>
                <Toolbar>
                    <img src={logo} alt="logo" className={classes.logo}/>
                </Toolbar>
            </AppBar>
        </div>
    );
}