import {makeStyles} from "@material-ui/styles";

export default makeStyles(theme => ({
    formContainer: {
        marginTop: theme.spacing(6),
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        marginBottom: theme.spacing(6)
    },
    div: {
        width: '70%',
        [theme.breakpoints.down("md")]: {
            width: '100%',
        },
    }
}));