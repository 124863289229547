import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({
    container: {
        height: "93vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "absolute",
        left: 0,
    },
    logotypeContainer: {
        backgroundColor: '#F6F7FF',
        width: "60%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        [theme.breakpoints.down("sm")]: {
            width: "100%",
            height: "40%",
        },
    },
    formContainer: {
        width: "40%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        [theme.breakpoints.down("md")]: {
            width: "50%",
        },
        backgroundColor: theme.palette.background.login
    },
    form: {
        width: 320,
        marginTop: '20px',
        marginBottom: '40px'
    },
    card: {
        borderRadius: '16px',
        boxShadow: theme.customShadows.widget
    },
    formDividerContainer: {
        display: "flex",
        alignItems: "center",
        marginBottom: '20%'
    },
    subGreeting: {
        fontWeight: 500,
        textAlign: "center",
        marginTop: theme.spacing(2),
        color: theme.palette.text.secondary
    },
    FormButton: {
        marginTop: theme.spacing(2.5),
        height: 46,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    Button: {
        height: 46,
        textTransform: "none",
        borderRadius: '25px',
        backgroundColor: '#4ad395',
        boxShadow: 'rgb(0 171 85 / 24%) 0px 8px 16px 0px',
        color: 'rgb(255, 255, 255)',
        fontWeight: 700
    },
}));