import {Snackbar} from "@material-ui/core";
import MuiAlert from '@material-ui/lab/Alert';

// styles
import useStyles from "./styles";

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
};

export default function AlertNotification(props) {

    const classes = useStyles();

    const {notify, setNotify} = props;

    //notification
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setNotify({
            ...notify,
            isOpen:false
        });
    };

    return (
        <Snackbar open={notify.isOpen} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{vertical: 'top', horizontal: 'right'}} className={classes.alertNotification}>
            <Alert onClose={handleClose} severity={notify.type} className={notify.type === 'error' ? classes.alertError : notify.type === 'info' ? classes.alertInfo : classes.alertSuccess}>
                {notify.msg} 
            </Alert>
        </Snackbar>
    );
};