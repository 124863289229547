import { useState, useEffect } from "react";
import { Grid, Container, CircularProgress } from "@material-ui/core";
import { withRouter } from "react-router-dom";

import Header from "../../components/Header/Header";

//table
import MUIDataTable from "mui-datatables";

//notification
import Alert from "../../components/Alert/Alert";

//service
import { getSponsor } from "../../services/index";

// styles
import useStyles from "./styles";

function ListSponsor() {
  const classes = useStyles();

  //local
  const [loading, setLoading] = useState(true);
  const [notify, setNotify] = useState({ isOpen: false, msg: "", type: "" });
  const [sponsor, setSponsor] = useState([]);

  //obtener patrocinadores
  const loadSponsors = async () => {
    const response = await getSponsor();

    if (response.status === 200) {
      setSponsor(response.data.sponsors);
      setLoading(false);
    } else {
      setNotify({
        isOpen: true,
        msg: "Error al obtener los patrocinadores",
        type: "error",
      });
      setLoading(false);
    }
  };

  //cargar clientes
  useEffect(() => {
    loadSponsors();
  }, []);

  //dataTable
  const data = sponsor;

  const columns = [
    {
      name: "id",
      label: "Número de Patrocinio",
    },
    {
      name: "nombre",
      label: "Nombre",
    },
    {
      name: "email",
      label: "Correo",
    },
    {
      name: "telefono",
      label: "Teléfono",
    },
    {
      name: "compania",
      label: "Empresa",
    },
    {
      name: "nino",
      label: "Niño",
    },
    {
      name: "edad",
      label: "Edad",
    },
    {
      name: "sexo",
      label: "Género",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return <>{value === 1 ? "Hombre" : "Mujer"}</>;
        },
      },
    },
    {
      name: "fecha_patrocinio",
      label: "Fecha Patrocinio",
    },
  ];

  return (
    <>
      <Header />
      <div className={classes.formContainer}>
        <div className={classes.div}>
          <Container>
            <Alert notify={notify} setNotify={setNotify} />
            <Grid item xs={12}>
              <MUIDataTable
                data={data}
                columns={columns}
                options={{
                  selectableRows: "none",
                  textLabels: {
                    body: {
                      noMatch: loading ? (
                        <CircularProgress size={26} />
                      ) : (
                        "No hay Resultados"
                      ),
                    },
                    pagination: {
                      next: "Siguiente Página",
                      previous: "Página Anterior",
                      rowsPerPage: "Filas por página:",
                      displayRows: "de",
                    },
                    toolbar: {
                      search: "Buscar",
                      downloadCsv: "Descargar CSV",
                      print: "Imprimir",
                      viewColumns: "Ver Columnas",
                      filterTable: "Filtrar Tabla",
                    },
                    filter: {
                      all: "TODO",
                      title: "FILTROS",
                      reset: "REINICIAR",
                    },
                    viewColumns: {
                      title: "Ver Columnas",
                      titleAria: "Mostrar/Ocultar Columnas",
                    },
                  },
                }}
              />
            </Grid>
          </Container>
        </div>
      </div>
    </>
  );
}

export default withRouter(ListSponsor);
