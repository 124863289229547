import {makeStyles} from "@material-ui/styles";

export default makeStyles(theme => ({
    alertNotification: {
        top: theme.spacing(9),
    },
    alertError: {
        backgroundColor: '#FF5C93'
    },
    alertInfo: {
        backgroundColor: '#0284FE'
    },
    alertSuccess: {
        backgroundColor: '#3CD4A0'
    }
}));