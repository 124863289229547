import {ThemeProvider, StylesProvider} from "@material-ui/styles";
import {CssBaseline} from "@material-ui/core";

import Themes from "./themes";
import App from "./components/App";

const AppTheme = () => {

    return (
        <StylesProvider injectFirst>
            <ThemeProvider theme={Themes.default} >
                <CssBaseline />
                <App />
            </ThemeProvider>
        </StylesProvider>
    );
};

export default AppTheme;