import {useState, useEffect} from "react";
import {
  Grid,
  Typography,
  Card,
  CardContent,
  Button
} from "@material-ui/core";
import {withRouter, Link} from "react-router-dom";

import Header from "../../components/Header/Header"; 

//notification
import Alert from "../../components/Alert/Alert";

// styles
import useStyles from "./styles";

//lottie
import Lottie from "lottie-react";
import animationData from '../../animations/gift.json'

function StarForm(props) {

    const classes = useStyles();

    // local
    const [notify, setNotify] = useState({isOpen: false, msg:'', type:''});
    const {name, age, sex} = props.location

    //validaciones error
    useEffect(() => {
        setNotify({
            isOpen: true,
            msg: 'Gracias por patrocinar una sonrisa!',
            type: 'success'
        })
    }, []);

    return (
        <>
            <Header />
            <Grid container className={classes.container}>
                <Alert notify={notify} setNotify={setNotify} />
                <Lottie animationData={animationData} className={classes.logotypeContainer} />
                <div className={classes.formContainer}>
                    <div className={classes.form}>
                        <Card className={classes.card}>
                            <CardContent>
                                <div className={classes.formDividerContainer}>
                                    <Typography variant="h2" className={classes.subGreeting}>
                                        ¡Muchas Gracias!
                                    </Typography>
                                </div>
                                <Typography variant="h6" weight="medium" className={classes.message}>
                                    Gracias por patrocinar una sonrisa en un@ de nuestr@s niñ@s y compartir esperanza en esta Navidad.
                                </Typography>
                                <Typography variant="h6" weight="medium">
                                    Patrocinas un regalo para:
                                </Typography>
                                <Typography variant="h6" weight="medium" >
                                    Niño: {name}
                                </Typography>
                                <Typography variant="h6" weight="medium">
                                    Edad: {age} años
                                </Typography>
                                <Typography variant="h6" weight="medium">
                                    Género: {sex === 1 ? 'Hombre' : 'Mujer'}
                                </Typography>
                                <Typography variant="h6" weight="medium" className={classes.message}>
                                    Te enviaremos un correo con la información y te contactaremos de parte de Milagros de Esperanza para coordinar la entrega del regalo.
                                </Typography>
                                <div className={classes.FormButton}>
                                    <Button
                                        size="large"
                                        variant="contained"
                                        fullWidth
                                        color="primary"
                                        className={classes.Button}
                                        component={Link}
                                        to="/"
                                    >
                                        INICIO
                                    </Button>
                                </div>
                            </CardContent>
                        </Card>
                    </div>
                </div>
            </Grid>
        </>
    );
}

export default withRouter(StarForm);