import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    title: {
        flexGrow: 1,
        color: 'white',
    },
    bar: {
        backgroundColor: theme.palette.background.header
    },
    logo: {
        width: '9%', 
        height: '9%',
        [theme.breakpoints.down("md")]: {
            width: "12%",
        },
        [theme.breakpoints.down("sm")]: {
            width: "16%",
        },
        [theme.breakpoints.down("xs")]: {
            width: "27%",
        },
    }
}));