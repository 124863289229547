import axios from 'axios';

const urlBase = process.env.REACT_APP_SERVERLESS;

export async function receiveKit(name, email, phone, company) {
    //patrocinador - recibir niño

    try {
        const response = await axios({
            url: `${urlBase}/receiveKit`,
            method: 'POST',
            data: {name: name, email: email, phone: phone, company: company}
        });

        return response;
    } catch (error) {
        console.log(error)
        return error.response
    }
};

export async function getSponsor() {
    //obtener sponsors

    try {
        const response = await axios({
            url: `${urlBase}/sponsor`,
            method: 'GET',
        });

        return response;
    } catch (error) {
      console.log(error)
      return error.response
    }
};