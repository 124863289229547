import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({
    container: {
        height: "93vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: theme.palette.primary.login,
        position: "absolute",
        left: 0,
    },
    logotype: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    logotypeIcon: {
        width: '50%',
    },
    logoError: {
        maxWidth: '100%',
        width: 560
    },
    backButton: {
        textTransform: "none",
        fontSize: 22,
        borderRadius: '25px',
        marginTop: theme.spacing(2),
        backgroundColor: '#4ad395',
        boxShadow: 'rgb(0 171 85 / 24%) 0px 8px 16px 0px',
        color: 'rgb(255, 255, 255)',
        fontWeight: 700
    },
}));