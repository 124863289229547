import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";

import UserForm from "../pages/Form/UserForm";
import CompanyForm from "../pages/Company/CompanyForm";
import StarForm from "../pages/Star/StarForm";
import Error from "../pages/Error/Error";
import ListSponsor from "../pages/List/List";

function App() {
  return (
    <div className="App">
      <Router>
        <Switch>
          <Route exact path="/" component={UserForm} />
          <Route path="/result" component={StarForm} />
          <Route path="/empresa" component={CompanyForm} />
          <Route path="/lista" component={ListSponsor} />
          <Route component={Error} />
        </Switch>
      </Router>
    </div>
  );
}

export default App;
